import { toast } from "react-toastify";
import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { forgotPassword } from "../../../api/auth.api";
// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes";
import { userForgetPasswordError } from "./actions";
import { userForgetPasswordSuccess } from "./actions";
//AUTH related methods
// import { getFirebaseBackend } from "../../../helpers/authUtils";

// const fireBaseBackend = getFirebaseBackend();

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { email, history } }) {
  try {
    const response = yield call(forgotPassword, email);
    if (response) {
      yield put(userForgetPasswordSuccess(response.message));
      history.push("/login");
      toast.success(response.message);
    }
  } catch (error) {
    yield put(userForgetPasswordError(error.data));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
