import agent from "./agent";

export const fetchingDashboard = async (payload) => {
  const result = await agent({
    method: "get",
    baseURL: process.env.REACT_APP_FVPN_WEB_URL,
    url: "/api/seller",
    params: payload,
  });

  return result;
};
