import {
  FETCHING_DASHBOARD_SUCCESS,
  FETCHING_DASHBOARD,
  FETCHING_DASHBOARD_FAILURE,
} from "./actionTypes";

export const fetchingDashboard = (payload) => {
  return {
    type: FETCHING_DASHBOARD,
    payload,
  };
};

export const fetchingDashboardSuccess = (payload) => {
  return {
    type: FETCHING_DASHBOARD_SUCCESS,
    payload,
  };
};

export const fetchingDashboardFailure = (payload) => {
  return {
    type: FETCHING_DASHBOARD_FAILURE,
    payload,
  };
};
