import axios from "axios";
// import store from "store";
import { CORE } from "../constants";

const instance = axios.create({
  baseURL: CORE.SERVER_URL,
});

instance.interceptors.request.use(
  function (config) {
    // config.headers["Authorization"] = "Bearer " + store.get("token", "");
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response.data;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error.response);
  }
);

export default instance;
