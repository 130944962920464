import Cookies from "js-cookie";

const expires = 1;
export function getSessionToken() {
  return Cookies.get("access-token");
}

export function resetSessionToken() {
  Cookies.remove("access-token");
}

export function setSessionToken(token) {
  Cookies.set("access-token", token, {
    expires,
    // secure: process.env.NODE_ENV === 'production',
  });
}
export function getLocalToken() {
  return localStorage.getItem("access-token");
}
export function removeLocalToken() {
  return localStorage.removeItem("access-token");
}
export function setLocalToken(token) {
  localStorage.setItem("access-token", JSON.stringify(token));
}

export function getToken() {
  return getLocalToken() || getSessionToken();
}

export function removeToken() {
  return resetSessionToken() || removeLocalToken();
}
