import {
  FETCHING_TRANSACTIONS,
  FETCHING_TRANSACTIONS_SUCCESS,
  FETCHING_TRANSACTIONS_FAILURE,
} from "./actionTypes";

export const fetchingTransactions = (payload) => {
  return {
    type: FETCHING_TRANSACTIONS,
    payload,
  };
};

export const fetchingTransactionsSuccess = (payload) => {
  return {
    type: FETCHING_TRANSACTIONS_SUCCESS,
    payload,
  };
};

export const fetchingTransactionsFailure = (payload) => {
  return {
    type: FETCHING_TRANSACTIONS_FAILURE,
    payload,
  };
};
