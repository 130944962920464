import React from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";
const MonthlyEarning = ({
  onClickChangeEarning,
  currentEarning,
  loading,
  totalSales,
}) => {
  return (
    <React.Fragment>
      {" "}
      <Card>
        <CardBody>
          <CardTitle className="mb-4 float-sm-left">
            Thu nhập tháng hiện tại
          </CardTitle>
          <div className="float-sm-right">
            <ul className="nav nav-pills">
              <li className="nav-item"></li>
              <li className="nav-item">
                <button
                  className={classNames("nav-link", {
                    active: currentEarning === "totalSales",
                  })}
                  onClick={() => onClickChangeEarning("totalSales")}
                >
                  Doanh số
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={classNames("nav-link", {
                    active: currentEarning === "incentive",
                  })}
                  onClick={() => onClickChangeEarning("incentive")}
                >
                  Incentive
                </button>
              </li>
            </ul>
          </div>
          <div className="clearfix"></div>
          <Row>
            <Col sm="6">
              <p className="text-muted">
                {currentEarning === "incentive"
                  ? "Tổng incentive"
                  : "Tổng doanh số bán hàng"}
              </p>
              <h3>
                {currentEarning === "incentive"
                  ? totalSales && totalSales.totalIncentive
                    ? totalSales.totalIncentive.toLocaleString("it-IT", {
                        style: "currency",
                        currency: "VND",
                      })
                    : undefined
                  : totalSales && totalSales.price
                  ? totalSales.price.toLocaleString("it-IT", {
                      style: "currency",
                      currency: "VND",
                    })
                  : undefined}
              </h3>

              {/* <p className="text-muted">
                <span className="text-success mr-2">
                  {" "}
                  12% <i className="mdi mdi-arrow-up"></i>{" "}
                </span>{" "}
                From previous period
              </p> */}
              <div className="mt-4">
                <Link
                  to="/transactions"
                  className="btn btn-primary waves-effect waves-light btn-sm"
                >
                  Xem chi tiết <i className="mdi mdi-arrow-right ml-1"></i>
                </Link>
              </div>
            </Col>
            <Col sm="6">
              <div className="mt-4 mt-sm-0">{/* <Gauge /> */}</div>
            </Col>
          </Row>
          <p className="text-muted mt-4 mb-3">
            We craft digital, graphic and dimensional thinking.
          </p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default MonthlyEarning;
