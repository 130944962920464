import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthLayout from "./Layouts";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";

import { fetchingVerifyEmail } from "../../store/actions";
import { isEmpty } from "lodash";
const VerifyEmail = ({ ...props }) => {
  const dispatch = useDispatch();
  const { VerifyEmail } = useSelectorVerifyEmail();
  const { loading } = VerifyEmail;
  const [status, setStatus] = useState({
    success: null,
    message: null,
  });
  useEffect(() => {
    const { token } = props.match.params;
    dispatch(fetchingVerifyEmail(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(VerifyEmail.verifyFailure)) {
      setStatus({
        success: false,
        message: VerifyEmail.verifyFailure.message,
      });
    }
    if (!isEmpty(VerifyEmail.verifySuccess)) {
      setStatus({
        success: true,
        message: VerifyEmail.verifySuccess.message,
      });
    }
  }, [VerifyEmail]);
  if (!loading) {
    return (
      <AuthLayout>
        {status ? (
          <Alert color={status.success ? "success" : "danger"} className="mb-0">
            <strong>{status.success ? "Congratulation" : "Oh snap!"}</strong>{" "}
            <br />
            {status.message}.
            <Link to="/" className="alert-link">
              Come back <i className="fas fa-home fa-500px"></i>
            </Link>
          </Alert>
        ) : undefined}
      </AuthLayout>
    );
  }
  return (
    <div className="loading">
      <div className="spinner-border text-info m-1" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default VerifyEmail;

const useSelectorVerifyEmail = () =>
  useSelector(({ VerifyEmail }) => ({ VerifyEmail }));
