import React, { useEffect, useReducer } from "react";
import { Row, Col, CardBody, Card } from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { get } from "lodash";
import { loginUser, loginFailed } from "../../store/actions";
import profile from "../../assets/images/profile-img.png";
import AuthLayout from "./Layouts";

const Login = ({ ...props }) => {
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.loginUser(values, props.history);
  };
  const [errors, setErrors] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {}
  );
  const error = get(props, "error", undefined);

  useEffect(() => {
    // props.loginFailed("");
  }, []);
  useEffect(() => {
    if (props.error) {
      setErrors(props.error);
    }
  }, [props.error]);
  return (
    <AuthLayout>
      <Card className="overflow-hidden">
        <div className="bg-soft-primary">
          <Row>
            <Col className="col-7">
              <div className="text-primary p-4">
                <h5 className="text-primary">Welcome Back !</h5>
                <p>Đăng nhập để tiếp tục sử dụng dịch vụ của FPT VPN.</p>
              </div>
            </Col>
            <Col className="col-5 align-self-end">
              <img src={profile} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <div>
            <Link to="/">
              <div className="avatar-md profile-user-wid mb-4">
                <span className="avatar-title rounded-circle bg-light">
                  <img
                    src="/images/logo.svg"
                    alt=""
                    className="rounded-circle"
                    height="34"
                  />
                </span>
              </div>
            </Link>
          </div>
          <div className="p-2">
            <AvForm
              className="form-horizontal"
              onValidSubmit={handleValidSubmit}
              onKeyDown={(e) => {
                const { name } = e?.target;
                if (error && error[name]) {
                  setErrors({ [name]: undefined });
                }
              }}
            >
              {/* {props.error && props.error ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null} */}

              <div className="form-group">
                <AvField
                  name="email"
                  label="Email"
                  className="form-control"
                  placeholder="Vui lòng nhập email công ty của bạn"
                  type="email"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Email không được để trống",
                    },
                    pattern: {
                      // eslint-disable-next-line no-useless-escape
                      value: /^[\w\.]{1,32}@(fpt.com.vn)$/,
                      errorMessage:
                        "Email không hợp lệ. Vui lòng sử dụng email công ty của bạn",
                    },
                    custom: (value, ctx, input, cb) => {
                      const { name } = input?.props;
                      if (errors && errors[name]) {
                        return cb(errors[name]);
                      }
                      cb(true);
                    },
                  }}
                />
              </div>

              <div className="form-group">
                <AvField
                  name="password"
                  label="Password"
                  type="password"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Mật khẩu không được để trống",
                    },
                    minLength: {
                      value: 6,
                      errorMessage: "Mật khẩu phải ít nhất 6 ký tự",
                    },
                    custom: (value, ctx, input, cb) => {
                      const { name } = input?.props;
                      if (errors && errors[name]) {
                        return cb(errors[name]);
                      }
                      cb(true);
                    },
                  }}
                  placeholder="********"
                />
              </div>

              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customControlInline"
                />
                <label
                  className="custom-control-label"
                  htmlFor="customControlInline"
                >
                  Nhớ mật khẩu
                </label>
              </div>

              <div className="mt-3">
                <button
                  className="btn btn-primary btn-block waves-effect waves-light"
                  type="submit"
                >
                  Đăng nhập
                </button>
              </div>

              <div className="mt-4 text-center">
                <Link to="/forget-password" className="text-muted">
                  <i className="mdi mdi-lock mr-1"></i> Quên mật khẩu?
                </Link>
              </div>
            </AvForm>
          </div>
        </CardBody>
      </Card>
    </AuthLayout>
  );
};

const mapStatetoProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStatetoProps, { loginUser, loginFailed })(Login)
);
