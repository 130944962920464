import {
  FETCHING_CHANGE_PASSWORD,
  FETCHING_CHANGE_PASSWORD_SUCCESS,
  FETCHING_CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
} from "./actionTypes";

export const fetchingChangePassword = (token, history) => {
  return {
    type: FETCHING_CHANGE_PASSWORD,
    payload: { token, history },
  };
};

export const fetchingChangePasswordSuccess = (message) => {
  return {
    type: FETCHING_CHANGE_PASSWORD_SUCCESS,
    payload: message,
  };
};

export const fetchingChangePasswordFailure = (message) => {
  return {
    type: FETCHING_CHANGE_PASSWORD_FAILURE,
    payload: message,
  };
};

export const changePasswordAction = (values, history) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { values, history },
  };
};
export const changePasswordSuccess = (payload) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload,
  };
};
export const changePasswordFailure = (payload) => {
  return {
    type: CHANGE_PASSWORD_FAILURE,
    payload,
  };
};
