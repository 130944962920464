import {
  FETCHING_TRANSACTIONS,
  FETCHING_TRANSACTIONS_SUCCESS,
  FETCHING_TRANSACTIONS_FAILURE,
} from "./actionTypes";

const initialState = {
  message: null,
  loading: false,
  data: null,
  pagination: null,
  errors: null,
};

const transactionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCHING_TRANSACTIONS: {
      state = {
        ...state,
        loading: true,
        data: null,
        message: null,
        pagination: null,
        errors: null,
      };
      break;
    }

    case FETCHING_TRANSACTIONS_SUCCESS: {
      state = {
        ...state,
        loading: false,
        // pagination: payload.pagination,
        data: payload.data,
        message: payload.message,
        errors: null,
      };
      break;
    }

    case FETCHING_TRANSACTIONS_FAILURE: {
      state = {
        ...state,
        loading: false,
        data: null,
        pagination: null,
        errors: payload,
      };
      break;
    }

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default transactionReducer;
