import {
  FETCHING_CHANGE_PASSWORD,
  FETCHING_CHANGE_PASSWORD_SUCCESS,
  FETCHING_CHANGE_PASSWORD_FAILURE,
} from "./actionTypes";

const initialState = {
  data: null,
  error: null,
  loading: true,
};

const changePassword = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_CHANGE_PASSWORD:
      state = {
        ...state,
        loading: true,
        data: null,
      };
      break;
    case FETCHING_CHANGE_PASSWORD_SUCCESS:
      state = {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
      break;
    case FETCHING_CHANGE_PASSWORD_FAILURE:
      state = {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default changePassword;
