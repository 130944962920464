import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { get } from "lodash";
//Account Redux states
import { FETCHING_VERIFY_EMAIL } from "./actionTypes";
import { verifyEmailFailure, verifyEmailSuccess } from "./actions";

//AUTH related methods
import * as authApi from "../../../api/auth.api";

// const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
function* verifyEmail({ payload }) {
  try {
    const response = yield call(authApi.verifyEmail, payload);
    if (response && response.success === true) {
      yield put(verifyEmailSuccess(response));
    } else {
      yield put(
        verifyEmailFailure(get(response, "message", "Server is error"))
      );
    }
  } catch (error) {
    yield put(verifyEmailFailure(get(error, "data", "Server is error")));
  }
}

export function* watchVerifyEmail() {
  yield takeEvery(FETCHING_VERIFY_EMAIL, verifyEmail);
}

function* verifyEmailSaga() {
  yield all([fork(watchVerifyEmail)]);
}

export default verifyEmailSaga;
