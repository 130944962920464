import {
  FETCHING_DASHBOARD,
  FETCHING_DASHBOARD_SUCCESS,
  FETCHING_DASHBOARD_FAILURE,
} from "./actionTypes";

const initialState = {
  message: null,
  loading: false,
  data: null,
  errors: null,
};

const dashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCHING_DASHBOARD: {
      state = {
        ...state,
        loading: true,
        data: null,
        errors: null,
      };
      break;
    }

    case FETCHING_DASHBOARD_SUCCESS: {
      state = {
        ...state,
        loading: false,
        data: payload,
        errors: null,
      };
      break;
    }

    case FETCHING_DASHBOARD_FAILURE: {
      state = {
        ...state,
        loading: false,
        data: null,
        errors: payload,
      };
      break;
    }

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default dashboardReducer;
