import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import VerifyEmail from "./auth/verifyEmail/reducer";
import Transactions from "./transactions//reducer";
import Dashboard from "./dashboard/reducer";
import ChangePassword from "./auth/changePassword/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  VerifyEmail,
  Transactions,
  Dashboard,
  ChangePassword,
});

export default rootReducer;
