import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { get } from "lodash";
//Account Redux states
import { FETCHING_DASHBOARD } from "./actionTypes";
import { fetchingDashboardFailure, fetchingDashboardSuccess } from "./actions";

//AUTH related methods
import * as dashboard from "../../api/dashboard";

// const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
function* fetchingDashboard({ payload }) {
  try {
    const response = yield call(dashboard.fetchingDashboard, payload);
    if (response && response.success === true) {
      yield put(fetchingDashboardSuccess(response));
    } else {
      yield put(
        fetchingDashboardFailure(get(response, "message", "Server is error"))
      );
    }
  } catch (error) {
    yield put(fetchingDashboardFailure(get(error, "data", "Server is error")));
  }
}

export function* watchFetchingDashboard() {
  yield takeEvery(FETCHING_DASHBOARD, fetchingDashboard);
}

function* dashboardSaga() {
  yield all([fork(watchFetchingDashboard)]);
}

export default dashboardSaga;
