import Header from "./Header.js";
import { Row, Col } from "reactstrap";
import Footer from "./Footer.js";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const AuthLayout = ({ children }) => {
  return (
    <React.Fragment>
      <Header />
      <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <ToastContainer />
              <div className="auth-main-content">{children}</div>
              <Footer />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default AuthLayout;
