import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Footer = (props) => {
  let location = useLocation();
  return (
    <div className="mt-5 text-center">
      <p>
        {location.pathname === "/login"
          ? "Don't have an account? "
          : "Already have an account? "}
        <Link
          to={location.pathname === "/login" ? "/register" : "/login"}
          className="font-weight-medium text-primary"
        >
          {" "}
          {location.pathname === "/login" ? "Register" : "Login"}
        </Link>{" "}
      </p>
      <p>
        © {new Date().getFullYear()} FPT VPN. Crafted with{" "}
        <i className="mdi mdi-heart text-danger"></i> by Fwork -
        tungdd5@fpt.com.vn
      </p>
    </div>
  );
};
export default Footer;
