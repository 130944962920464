import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { get } from "lodash";
//Account Redux states
import { FETCHING_TRANSACTIONS } from "./actionTypes";
import {
  fetchingTransactionsSuccess,
  fetchingTransactionsFailure,
} from "./actions";

//AUTH related methods
import * as transactions from "../../api/transactions";

// const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
function* fetchingTransactions({ payload }) {
  try {
    const response = yield call(transactions.fetchingTransactions, payload);
    if (response && response.success === true) {
      yield put(fetchingTransactionsSuccess(response));
    } else {
      yield put(
        fetchingTransactionsFailure(get(response, "message", "Server is error"))
      );
    }
  } catch (error) {
    yield put(
      fetchingTransactionsFailure(get(error, "data", "Server is error"))
    );
  }
}

export function* watchFetchingTransactions() {
  yield takeEvery(FETCHING_TRANSACTIONS, fetchingTransactions);
}

function* transactionSaga() {
  yield all([fork(watchFetchingTransactions)]);
}

export default transactionSaga;
