import React from "react";
import { isEmpty } from "lodash";
import { Row, Col, Card, CardBody, Media } from "reactstrap";
import { useSelector } from "react-redux";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import profileImg from "../../assets/images/profile-img.png";

const WelcomeComp = () => {
  const { currentUser } = useSelectorWelcomeComp();
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-soft-primary">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back !</h5>
                <p>{currentUser.email}</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={avatar1}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5 className="font-size-15 text-truncate">
                {currentUser.fullName}
              </h5>
              <p className="text-muted mb-0 text-truncate">FPT VPN</p>
            </Col>

            {!isEmpty(currentUser.code)
              ? currentUser.code.map((el, key) => (
                  <Col md="8" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody style={{ backgroundColor: "#fff" }}>
                        <Media style={{ backgroundColor: "#fff" }}>
                          <Media body>
                            <p className="text-muted font-weight-medium">
                              Mã khuyến mại
                            </p>

                            <h4 className="mb-0">{el.code}</h4>
                          </Media>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">{el.type} %</span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>
                ))
              : undefined}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default WelcomeComp;

const useSelectorWelcomeComp = () => useSelector(({ Login }) => ({ ...Login }));
