import { all } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import LayoutSaga from "./layout/saga";
import VerifyEmailSaga from "./auth/verifyEmail/saga";
import TransactionSaga from "./transactions/saga";
import Dashboard from "./dashboard/saga";
import ChangePassword from "./auth/changePassword/saga";
export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    AuthSaga(),
    ForgetSaga(),
    LayoutSaga(),
    VerifyEmailSaga(),
    TransactionSaga(),
    Dashboard(),
    ChangePassword(),
  ]);
}
