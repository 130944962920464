import {
  FETCHING_VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILED,
} from "./actionTypes";

export const fetchingVerifyEmail = (payload) => {
  return {
    type: FETCHING_VERIFY_EMAIL,
    payload,
  };
};

export const verifyEmailSuccess = (payload) => {
  return {
    type: VERIFY_EMAIL_SUCCESS,
    payload,
  };
};

export const verifyEmailFailure = (payload) => {
  return {
    type: VERIFY_EMAIL_FAILED,
    payload,
  };
};
