import { toast } from "react-toastify";
import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { fetchingChangePassword, changePassword } from "../../../api/auth.api";
// Login Redux States
import { FETCHING_CHANGE_PASSWORD, CHANGE_PASSWORD } from "./actionTypes";
import {
  fetchingChangePasswordSuccess,
  fetchingChangePasswordFailure,
  changePasswordSuccess,
  changePasswordFailure,
} from "./actions";
//AUTH related methods
// import { getFirebaseBackend } from "../../../helpers/authUtils";

// const fireBaseBackend = getFirebaseBackend();

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* checkChangePassword({ payload: { token, history } }) {
  try {
    const response = yield call(fetchingChangePassword, token);
    if (response.success) {
      yield put(fetchingChangePasswordSuccess(response.message));
      toast.success(response.message);
    }
  } catch (error) {
    history.push("/login");
    toast.error(error.data.message);
    yield put(fetchingChangePasswordFailure(error.data));
  }
}

function* updatePassword({ payload: { values, history } }) {
  try {
    const response = yield call(changePassword, values);
    if (response.success) {
      yield put(changePasswordSuccess(response.message));
      history.push("/login");
      toast.success(response.message);
    }
  } catch (error) {
    yield put(changePasswordFailure(error.data.message));
    console.log("function*updatePassword -> error", error);
  }
}

export function* watchFetchingChangePassword() {
  yield takeEvery(FETCHING_CHANGE_PASSWORD, checkChangePassword);
}

export function* watchChangePassword() {
  yield takeEvery(CHANGE_PASSWORD, updatePassword);
}

function* changePasswordSaga() {
  yield all([fork(watchFetchingChangePassword), fork(watchChangePassword)]);
}

export default changePasswordSaga;
