import React from "react";
import ReactEcharts from "echarts-for-react";

const Pie = ({ totalPackage }) => {
  const getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        left: "left",
        data: ["Gói 1 tháng", "Gói 7 tháng", "Gói 14 tháng"],
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#556ee6", "#f1b44c", "#34c38f"],
      series: [
        {
          name: "Số lượng gói cước",
          type: "pie",
          radius: "55%",
          center: ["50%", "60%"],
          data: [
            {
              value: totalPackage ? totalPackage.first.total : undefined,
              name: "Gói 1 tháng",
            },
            {
              value: totalPackage ? totalPackage.second.total : undefined,
              name: "Gói 7 tháng",
            },
            {
              value: totalPackage ? totalPackage.third.total : undefined,
              name: "Gói 14 tháng",
            },
            // { value: 135, name: "Others" },
            // { value: 1548, name: "Desktop" },
          ],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
  };
  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={getOption()} />
    </React.Fragment>
  );
};
// class Pie extends Component {

// }
export default Pie;
