import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
} from "reactstrap";
import { get, groupBy, isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";
import { fetchingDashboard } from "../../store/dashboard/actions";
// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import StackedColumnChart from "./StackedColumnChart";
import moment from "moment";
import Pie from "./piechart";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const groupByMonth = (arr) => {
  let resultMap = {};
  if (!isEmpty(arr)) {
    resultMap = groupBy(arr, "_id.month");
  }

  return Array.from(Array(12).keys()).map((el) => {
    const month = el + 1;
    const count = resultMap[month] ? resultMap[month][0].totalSales : 0;
    return count;
  });
};
const setTotalCurrent = (arr) => {
  if (!isEmpty(arr)) {
    let resultMap = {};
    const month = moment().format("M");
    resultMap = groupBy(arr, "_id.month");
    return {
      total: resultMap[month][0].total,
      totalSales: resultMap[month][0].totalSales,
    };
  }
};
const Dashboard = () => {
  const { Login, Dashboard } = useSelectorDashboard();
  const dispatch = useDispatch();
  const [totalSales, setTotalSales] = useState();
  const [currentEarning, setCurrentEarning] = useState("totalSales");
  const [totalPackage, setTotalPackage] = useState();
  const [dataChart, setDataChart] = useState({});
  // const totalOder = get(Dashboard, "data.totalOder.total", 0);

  useEffect(() => {
    if (Dashboard.data && Dashboard.data.success) {
      const firstPackage = get(Dashboard, "data.type1ByMonth", undefined);
      const secondPackage = get(Dashboard, "data.type2ByMonth", undefined);
      const thirdPackage = get(Dashboard, "data.type3ByMonth", undefined);

      const firstPackageByMonth = get(
        Dashboard,
        "data.firstPackageByMonth",
        undefined
      );
      const secondPackageByMonth = get(
        Dashboard,
        "data.secondPackageByMonth",
        undefined
      );
      const thirdPackageByMonth = get(
        Dashboard,
        "data.thirdPackageByMonth",
        undefined
      );
      setTotalSales({
        price:
          firstPackage.totalSales +
          secondPackage.totalSales +
          thirdPackage.totalSales,
        totalIncentive:
          firstPackage.totalIncentive +
          secondPackage.totalIncentive +
          thirdPackage.totalIncentive,
      });
      setTotalPackage({
        first: setTotalCurrent(firstPackageByMonth),
        second: setTotalCurrent(secondPackageByMonth),
        third: setTotalCurrent(thirdPackageByMonth),
      });
      setDataChart({
        firstPackageByMonth: groupByMonth(firstPackageByMonth, "_id"),
        secondPackageByMonth: groupByMonth(secondPackageByMonth, "_id"),
        thirdPackageByMonth: groupByMonth(thirdPackageByMonth, "_id"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Dashboard.data]);

  const dataChartInfo = !isEmpty(dataChart)
    ? {
        data: [
          {
            name: "Gói 1 tháng",
            data: dataChart.firstPackageByMonth,
          },
          {
            name: "Gói 7 tháng",
            data: dataChart.secondPackageByMonth,
          },
          {
            name: "Gói 14 tháng",
            data: dataChart.thirdPackageByMonth,
          },
        ],
      }
    : [];
  const [modal, setModal] = useState(false);

  const togglemodal = () => {
    setModal((modal) => !modal);
  };

  useEffect(() => {
    dispatch(
      fetchingDashboard({
        sellerId: Login.currentUser.id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onClickChangeEarning = async (dateTime) => {
    await setCurrentEarning(dateTime);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            titleLink="/"
            title="Home"
            itemLink="/dashboard"
            breadcrumbItem="Dashboard"
          />

          <Row>
            <Col xl="4">
              <WelcomeComp />
              <MonthlyEarning
                onClickChangeEarning={onClickChangeEarning}
                currentEarning={currentEarning}
                totalSales={totalSales}
                loading={Dashboard.loading}
              />
            </Col>
            <Col xl="8">
              <Row>
                {/* Reports Render */}

                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Media>
                        <Media body>
                          <p className="text-muted font-weight-medium">
                            Số lượng gói 1 tháng
                          </p>
                          <h4 className="mb-0">
                            {totalPackage
                              ? totalPackage.first.totalSales.toLocaleString(
                                  "it-IT",
                                  {
                                    style: "currency",
                                    currency: "VND",
                                  }
                                )
                              : 0}
                          </h4>
                          <span className="span-total-package">
                            {totalPackage
                              ? totalPackage.first.total
                              : undefined}{" "}
                            Tài khoản
                          </span>
                        </Media>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i className="fab fa-uikit" />
                          </span>
                        </div>
                      </Media>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Media>
                        <Media body>
                          <p className="text-muted font-weight-medium">
                            Số lượng gói 7 tháng
                          </p>
                          <h4 className="mb-0">
                            {totalPackage
                              ? totalPackage.second.totalSales.toLocaleString(
                                  "it-IT",
                                  {
                                    style: "currency",
                                    currency: "VND",
                                  }
                                )
                              : 0}
                          </h4>
                          <span className="span-total-package">
                            {totalPackage
                              ? totalPackage.second.total
                              : undefined}{" "}
                            Tài khoản
                          </span>
                        </Media>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i className="fab fa-uikit" />
                          </span>
                        </div>
                      </Media>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Media>
                        <Media body>
                          <p className="text-muted font-weight-medium">
                            Số lượng gói 14 tháng
                          </p>

                          <h4 className="mb-0">
                            {totalPackage
                              ? totalPackage.third.totalSales.toLocaleString(
                                  "it-IT",
                                  {
                                    style: "currency",
                                    currency: "VND",
                                  }
                                )
                              : 0}
                          </h4>
                          <span className="span-total-package">
                            {totalPackage
                              ? totalPackage.third.total
                              : undefined}{" "}
                            Tài khoản
                          </span>
                        </Media>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i className="fab fa-uikit" />
                          </span>
                        </div>
                      </Media>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <CardTitle>Thống kê gói cước</CardTitle>
                      <div id="pie-chart" className="e-chart">
                        <Pie totalPackage={totalPackage} />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4 float-sm-left">
                    Thống kê doanh số bán hàng
                  </CardTitle>
                  <div className="clearfix"></div>
                  {!isEmpty(dataChart) ? (
                    <StackedColumnChart dataChartInfo={dataChartInfo} />
                  ) : undefined}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabindex="-1"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>Order Details</ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <h6 className="m-0 text-right">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <h6 className="m-0 text-right">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <h6 className="m-0 text-right">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={togglemodal}>
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Dashboard;
const useSelectorDashboard = () =>
  useSelector(({ Login, Dashboard }) => ({ Login, Dashboard }));
