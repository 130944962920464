import React from "react";
import { Redirect } from "react-router-dom";

// // Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import VerifyEmail from "../pages/Authentication/VerifyEmail";
import ChangePassword from "../pages/Authentication/ChangePassword";
import Transactions from "../pages/Transactions";
// // Dashboard
import Dashboard from "../pages/Dashboard/index";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/logout", component: Logout },
  { path: "/transactions", component: Transactions },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/forget-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/verify-email/:token", component: VerifyEmail },
  { path: "/change-password/:token", component: ChangePassword },
];

export { authProtectedRoutes, publicRoutes };
