import React, { useReducer, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
// import { get } from "lodash";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import AuthLayout from "./Layouts";
import { userForgetPassword } from "../../store/actions";
import profile from "../../assets/images/profile-img.png";

const ForgetPasswordPage = ({ ...props }) => {
  const [errors, setErrors] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {}
  );

  useEffect(() => {
    if (props.forgetError) {
      setErrors(props.forgetError.errors);
    }
  }, [props.forgetError]);

  const handleValidSubmit = (event, values) => {
    props.userForgetPassword(values, props.history);
  };

  return (
    <AuthLayout>
      <Card className="overflow-hidden">
        <div className="bg-soft-primary">
          <Row>
            <Col className="col-7">
              <div className="text-primary p-4">
                <h5 className="text-primary">Quên mật khẩu</h5>
                <p>
                  Vui lòng nhập địa chỉ email của bạn để tạo 1 yêu cầu cấp lại
                  mật khẩu
                </p>
              </div>
            </Col>
            <Col className="col-5 align-self-end">
              <img src={profile} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <div>
            <Link to="#">
              <div className="avatar-md profile-user-wid mb-4">
                <span className="avatar-title rounded-circle bg-light">
                  <img
                    src="/images/logo.svg"
                    alt=""
                    className="rounded-circle"
                    height="34"
                  />
                </span>
              </div>
            </Link>
          </div>
          <div className="p-2">
            <AvForm
              className="form-horizontal mt-4"
              onValidSubmit={handleValidSubmit}
            >
              <div className="form-group">
                <AvField
                  name="email"
                  label="Email"
                  className="form-control"
                  placeholder="Vui lòng nhập email của bạn"
                  type="email"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Email không được để trống",
                    },
                    pattern: {
                      // eslint-disable-next-line no-useless-escape
                      value: /^[\w\.]{1,32}@(fpt.com.vn)$/,
                      errorMessage:
                        "Email không hợp lệ. Vui lòng sử dụng email công ty của bạn",
                    },
                    custom: (value, ctx, input, cb) => {
                      const { name } = input?.props;
                      if (errors && errors[name]) {
                        return cb(errors[name]);
                      }
                      cb(true);
                    },
                  }}
                />
              </div>
              <Row className="form-group">
                <Col className="text-right">
                  <button
                    className="btn btn-primary w-md waves-effect waves-light"
                    type="submit"
                  >
                    Cấp lại mật khẩu
                  </button>
                </Col>
              </Row>
            </AvForm>
          </div>
        </CardBody>
      </Card>
    </AuthLayout>
  );
};

const mapStatetoProps = (state) => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
);
