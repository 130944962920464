import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { get, isEmpty } from "lodash";
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import {
  loginSuccess,
  logoutUserSuccess,
  loginFailed,
  setCurrentUser,
} from "./actions";
import jwtDecode from "jwt-decode";
import { roleAllowed } from "../../../configs";

import { setLocalToken, removeToken } from "../../../common/tokenStorages";
import * as authApi from "../../../api/auth.api";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(authApi.loginUser, user.email, user.password);
    if (response && response.success === true) {
      const token = get(response, "data.accessToken", null);
      if (isEmpty(token)) {
        return yield put(loginFailed("Login failure"));
      }
      const decode = jwtDecode(token);
      if (roleAllowed.includes(decode.role)) {
        setLocalToken(token);
        yield put(setCurrentUser(decode));
        yield put(loginSuccess(response.data));
        return history.push("/dashboard");
      }
      yield put(
        loginFailed({
          email:
            "Bạn chưa có quyền truy cập hệ thống. Xin vui lòng liên hệ quản trị viên",
        })
      );
    }
  } catch (error) {
    yield put(loginFailed(get(error, "data.errors", "Server is error")));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    // const response = yield call(fireBaseBackend.logout);
    removeToken();
    yield put(logoutUserSuccess());
    history.push("/login");
  } catch (error) {
    yield put(loginFailed(get(error, "response.message", "Server is error")));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
