import {
  FETCHING_VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILED,
} from "./actionTypes";

const initialState = {
  verifySuccess: null,
  message: null,
  loading: false,
  verifyFailure: null,
};

const verifyEmail = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCHING_VERIFY_EMAIL: {
      state = {
        ...state,
        loading: true,
        verifySuccess: null,
        verifyFailure: null,
        message: null
      };
      break;
    }

    case VERIFY_EMAIL_SUCCESS: {
      state = {
        ...state,
        loading: false,
        verifySuccess: payload,
        message: payload.message,
        verifyFailure: null,
      };
      break;
    }

    case VERIFY_EMAIL_FAILED: {
      state = {
        ...state,
        loading: false,
        verifySuccess: null,
        verifyFailure: payload,
      };
      break;
    }

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default verifyEmail;
