import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { get } from "lodash";
//Account Redux states
import { REGISTER_USER } from "./actionTypes";
import { registerUserSuccessful, registerUserFailed } from "./actions";
import { toast } from "react-toastify";
//AUTH related methods
import * as authApi from "../../../api/auth.api";

// const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload, history }) {
  try {
    const response = yield call(authApi.registerUser, payload);
    if (response && response.success === true) {
      yield put(registerUserSuccessful(response.data));
      history.push("/login");
      toast.success(response.message);
    } else {
      yield put(
        registerUserFailed(get(response, "message", "Server is error"))
      );
    }
  } catch (error) {
    yield put(registerUserFailed(get(error, "data", "Server is error")));
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
}

function* registerSaga() {
  yield all([fork(watchUserRegister)]);
}

export default registerSaga;
