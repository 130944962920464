import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Badge,
} from "reactstrap";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import { isEmpty } from "lodash";
import { get } from "lodash";
import { fetchingTransactions } from "../../store/transactions/actions";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Transactions = (props) => {
  const dispatch = useDispatch();
  const { currentUser, Transactions } = useSelectorTransactions();
  const data = get(Transactions, "data", undefined);
  // eslint-disable-next-line no-unused-expressions
  !isEmpty(data)
    ? data.map((el) => {
        if (el.payDate) {
          el.payDate = moment(el.payDate).format(" DD/MM/YYYY - hh:mm");
        }
        if (el.price) {
          el.price = parseInt(el.price).toLocaleString("it-IT", {
            style: "currency",
            currency: "VND",
          });
        }
        if (el.discountType) {
          let color;
          el.discountType === 10
            ? (color = "warning")
            : el.discountType === 15
            ? (color = "danger")
            : (color = "success");
          el.discountType = (
            <Badge
              className={"font-size-12 badge-soft-" + color}
              color={color}
              pill
            >
              {el.discountType} %
            </Badge>
          );
        }
        if (el.typePackage) {
          let month;
          el.typePackage === 3
            ? (month = "14 month")
            : el.typePackage === 2
            ? (month = "7 month")
            : (month = "1 month");
          el.typePackage = month;
        }
        return el;
      })
    : undefined;

  useEffect(() => {
    dispatch(fetchingTransactions({ sellerId: currentUser.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataInfo = {
    columns: [
      {
        label: "User",
        field: "phone",
        sort: "asc",
        width: 150,
      },
      {
        label: "Amount Package",
        field: "amountPackage",
        sort: "asc",
        width: 270,
      },
      {
        label: "Type Package",
        field: "typePackage",
        sort: "asc",
        width: 100,
      },
      {
        label: "Price",
        field: "price",
        sort: "asc",
        width: 200,
      },
      {
        label: "Pay Method",
        field: "payMethod",
        sort: "asc",
        width: 150,
      },
      {
        label: "Discount",
        field: "discountType",
        sort: "asc",
        width: 100,
      },
      {
        label: "Pay Date",
        field: "payDate",
        sort: "asc",
        width: 100,
      },
    ],
    rows: data,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            titleLink="/"
            title="Home"
            itemLink="/transactions"
            breadcrumbItem="Transactions"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle>Transaction table</CardTitle>
                  <CardSubtitle className="mb-3">
                    The table displays a list of{" "}
                    <code>payment transactions</code>
                  </CardSubtitle>
                  {data ? (
                    <MDBDataTable responsive striped bordered data={dataInfo} />
                  ) : undefined}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Transactions;
const useSelectorTransactions = () =>
  useSelector(({ Login, Transactions }) => ({ ...Login, Transactions }));
