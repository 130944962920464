import { getToken } from "../../../common/tokenStorages";
import { isEmpty } from "lodash";
import jwtDecode from "jwt-decode";
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGIN_FAILED,
  SET_CURRENT_USER,
} from "./actionTypes";

import { roleAllowed } from "../../../configs";
import { removeToken } from "../../../common/tokenStorages";
const initialState = {
  currentUser: null,
  error: null,
  loading: false,
};

const login = (state = initialLoggedState(), action) => {
  switch (action.type) {
    case LOGIN_USER: {
      state = {
        ...state,
        currentUser: null,
        loading: true,
      };
      break;
    }

    case LOGIN_SUCCESS: {
      state = {
        ...state,
        loading: false,
      };
      break;
    }

    case SET_CURRENT_USER: {
      state = {
        ...state,
        currentUser: {
          id: action.payload._id,
          email: action.payload.email,
          role: action.payload.role,
          fullName: action.payload.fullName,
          phone: action.payload.phone,
          code: action.payload.code,
        },
        isLogged: !isEmpty(action.payload),
      };
      break;
    }

    case LOGOUT_USER:
      state = { ...state, currentUser: null };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state, currentUser: null };
      break;
    case LOGIN_FAILED:
      state = {
        ...state,
        currentUser: null,
        error: action.payload,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;

const initialLoggedState = () => {
  try {
    const token = JSON.parse(getToken());
    const decode = jwtDecode(token);
    if (!roleAllowed.includes(decode.role)) {
      removeToken();
      return {
        ...initialState,
        isLogged: false,
      };
    }
    return {
      ...initialState,
      currentUser: {
        id: decode._id,
        email: decode.email,
        role: decode.role,
        fullName: decode.fullName,
        phone: decode.phone,
        code: decode.code,
      },
      isLogged: token ? true : false,
    };
  } catch (error) {
    // console.log("initialLoggedState -> error", error)
    return {
      ...initialState,
      isLogged: false,
    };
  }
};
