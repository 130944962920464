import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty, get } from "lodash";
import { roleAllowed } from "../configs";
const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (
        isAuthProtected &&
        (!rest.currentUser || !roleAllowed.includes(rest.currentUser.role))
      ) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }
      if (
        !isAuthProtected &&
        (!isEmpty(rest.currentUser) ||
          roleAllowed.includes(get(rest, "currentUser.role", null)))
      ) {
        return (
          <Redirect
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        );
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

const mapStatetoProps = (state) => {
  const { currentUser } = state.Login;
  return { currentUser };
};

export default connect(mapStatetoProps, {})(AppRoute);
