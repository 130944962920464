import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="auth-header">
      <Link to="/" className="text-dark logo-header">
        {/* <img
          className="img-fluid"
          src="https://dev.fpt.work/images/FTI/15c12a77-2b45-4057-bb0a-aa07fb1d8a29.svg"
        /> */}
      </Link>
    </div>
  );
};
export default Header;
