import agent from "./agent";

export const registerUser = async (payload) => {
  const result = await agent.post("/auth/register/sale", payload);

  return result;
};

export const loginUser = async (email, password) => {
  const result = await agent.post("/auth/login", {
    email,
    password,
  });

  return result;
};
export const verifyEmail = async (token) => {
  const result = await agent.get(`/auth/verify-email/${token}`);

  return result;
};

export const forgotPassword = async (email) => {
  const result = await agent.post("/auth/forgot-password", email);
  return result;
};

export const fetchingChangePassword = async (token) => {
  const result = await agent.get(`/auth/change-password/${token}`);
  return result;
};

export const changePassword = async (params) => {
  const { token, ...value } = params;
  const result = await agent.put(`/auth/change-password/${token}`, value);
  return result;
};
