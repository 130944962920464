import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthLayout from "./Layouts";
import { Card, Row, Col, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import profile from "../../assets/images/profile-img.png";

import {
  fetchingChangePassword,
  changePasswordAction,
} from "../../store/actions";

const ChangePassword = ({ ...props }) => {
  const { token } = props.match.params;
  const dispatch = useDispatch();

  const { ChangePassword } = useSelectorChangePassword();
  const { loading } = ChangePassword;

  const handleSubmit = (event, values) => {
    values.token = token;

    dispatch(changePasswordAction(values, props.history));
  };

  useEffect(() => {
    dispatch(fetchingChangePassword(token, props.history));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loading) {
    return (
      <AuthLayout>
        <Card className="overflow-hidden">
          <div className="bg-soft-primary">
            <Row>
              <Col className="col-7">
                <div className="text-primary p-4">
                  <h5 className="text-primary">Cài đặt lại mật khẩu</h5>
                  <p>Nhập mật khẩu để tiếp tục sử dụng dịch vụ của FPT VPN.</p>
                </div>
              </Col>
              <Col className="col-5 align-self-end">
                <img src={profile} alt="" className="img-fluid" />
              </Col>
            </Row>
          </div>
          <CardBody className="pt-0">
            <div>
              <Link to="/">
                <div className="avatar-md profile-user-wid mb-4">
                  <span className="avatar-title rounded-circle bg-light">
                    <img
                      src="/images/logo.svg"
                      alt=""
                      className="rounded-circle"
                      height="34"
                    />
                  </span>
                </div>
              </Link>
            </div>
            <div className="p-2">
              <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                <div className="form-group">
                  <AvField
                    name="password"
                    label="Mật khẩu mới"
                    type="password"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Mật khẩu không được để trống",
                      },
                      minLength: {
                        value: 6,
                        errorMessage: "Mật khẩu phải ít nhất 6 ký tự",
                      },
                    }}
                    placeholder="********"
                  />
                </div>
                <div className="form-group">
                  <AvField
                    name="confirmPassword"
                    label="Nhập lại mật khẩu mới"
                    type="password"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Mật khẩu không được để trống",
                      },
                      minLength: {
                        value: 6,
                        errorMessage: "Mật khẩu phải ít nhất 6 ký tự",
                      },
                      custom: (value, ctx, input, cb) => {
                        if (ctx.password !== ctx.confirmPassword) {
                          return cb("Mật khẩu không trùng nhau");
                        }
                        cb(true);
                      },
                    }}
                    placeholder="********"
                  />
                </div>

                <div className="mt-3">
                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                  >
                    Thay đổi mật khẩu
                  </button>
                </div>
              </AvForm>
            </div>
          </CardBody>
        </Card>
      </AuthLayout>
    );
  }
  return (
    <div className="loading">
      <div className="spinner-border text-info m-1" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default ChangePassword;

const useSelectorChangePassword = () =>
  useSelector(({ ChangePassword }) => ({ ChangePassword }));
