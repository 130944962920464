import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { logoutUser } from "../../store/actions";

const Logout = ({ ...props }) => {
  useEffect(() => {
    props.logoutUser(props.history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <React.Fragment></React.Fragment>;
};

export default withRouter(connect(null, { logoutUser })(Logout));
