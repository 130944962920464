import React, { useEffect, useReducer } from "react";
import { Row, Col, CardBody, Card } from "reactstrap";
import AuthLayout from "./Layouts";
import classNames from "classnames";
// availity-reactstrap-validation
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";

// action
import { registerUser, registerUserFailed } from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";

const Register = ({ registrationError, ...props }) => {
  const [errors, setErrors] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {}
  );
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.registerUser({ ...values, history: props.history });
  };

  useEffect(() => {
    if (registrationError) {
      setErrors(registrationError.errors);
    }
  }, [registrationError]);

  return (
    <AuthLayout>
      <Card className="overflow-hidden">
        <div className="bg-soft-primary">
          <Row>
            <Col className="col-7">
              <div className="text-primary p-4">
                <h5 className="text-primary">Free Register</h5>
                <p>Get your free FPT Seller account now.</p>
              </div>
            </Col>
            <Col className="col-5 align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <div>
            <Link to="/login">
              <div className="avatar-md profile-user-wid mb-4">
                <span className="avatar-title rounded-circle bg-light">
                  <img
                    src="/images/logo.svg"
                    alt=""
                    className="rounded-circle"
                    height="34"
                  />
                </span>
              </div>
            </Link>
          </div>
          <div className="p-2">
            <AvForm
              // ref={avFormRef}
              className="form-horizontal"
              onValidSubmit={handleValidSubmit}
              onKeyDown={(e) => {
                const { name } = e?.target;
                if (errors[name]) {
                  setErrors({ [name]: undefined });
                }
              }}
              // onInvalidSubmit={(e, errors) => {console.log(avFormRef)}}
            >
              <div className="form-group">
                <AvGroup className="field-email">
                  <AvField
                    name="email"
                    label="Email"
                    className={classNames("form-control", {})}
                    placeholder="tungdd5@fpt.com.vn"
                    type="email"
                    required
                    validate={{
                      pattern: {
                        // eslint-disable-next-line no-useless-escape
                        value: /^[\w\.]{1,32}@(fpt.com.vn)$/,
                        errorMessage: "Please use your company email",
                      },
                      custom: (value, ctx, input, cb) => {
                        const { name } = input?.props;
                        if (errors[name]) {
                          return cb(errors[name]);
                        }
                        cb(true);
                      },
                    }}
                  />
                </AvGroup>
              </div>

              <div className="form-group">
                <AvField
                  name="password"
                  label="Password"
                  type="password"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter your password",
                    },
                    minLength: {
                      value: 6,
                      errorMessage:
                        "Password length must be at least 6 characters long",
                    },
                  }}
                  placeholder="********"
                />
              </div>
              <div className="form-group">
                <AvField
                  name="fullName"
                  label="Full name"
                  type="text"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please input your full name",
                    },
                  }}
                  required
                  placeholder="Enter your name"
                />
              </div>
              <div className="form-group">
                <AvField
                  name="phone"
                  label="Phone"
                  type="text"
                  required
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter your phone number",
                    },
                    pattern: {
                      value: /^(0|\+84)(\s|\.)?((3[2-9])|(5[689])|(7[06-9])|(8[1-689])|(9[0-46-9]))(\d)(\s|\.)?(\d{3})(\s|\.)?(\d{3})$/,
                      errorMessage: "Phone number invalid",
                    },
                  }}
                  placeholder="012 345 6789"
                />
              </div>

              <div className="mt-4">
                <button
                  disabled={props.loading}
                  className="btn btn-primary btn-block waves-effect waves-light"
                  type="submit"
                >
                  Register
                </button>
              </div>

              <div className="mt-4 text-center">
                <p className="mb-0">
                  By registering you agree to the FPT VPN{" "}
                  <Link
                    to="https://fptvpn.com/policy-and-regulations"
                    className="text-primary"
                  >
                    Terms of Use
                  </Link>
                </p>
              </div>
            </AvForm>
          </div>
        </CardBody>
      </Card>
    </AuthLayout>
  );
};

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  registerUserFailed,
})(Register);
